.background-img {
 

  &__1 {
    position: absolute;
    z-index: -1;
    top: 50em;
    right: 0;
    transform: translateZ(0);
  }
  &__2{
    position: absolute;
    z-index: -1;
    bottom: 50em;
    right:50%;
  }
}
